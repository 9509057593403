import React from 'react';
import { connect } from 'react-redux';
import { GET_VENDORS } from '../actions/vendorActions';
import { routeCodes } from './Routes';
import {NavLink} from "react-router-dom";
import { asyncLocalStorage, getLoggedInUser } from '../globals/utils';

const logout = () => {
    asyncLocalStorage.removeItem("userData").then(function (value) {
        window.location = "/login";
    });
}

let userData = getLoggedInUser();

const Sidebar = () =>
<>
<div id="aside" className="page-sidenav no-shrink bg-light nav-dropdown fade" aria-hidden="true">
    <div className="sidenav h-100 modal-dialog bg-light">
        <div className="navbar">
            <a href="index.html" className="navbar-brand">
                <img src="./../img/custom/logo.png" alt="..." />
                <span className="hidden-folded d-inline l-s-n-1x logo-text-color">D - Barber</span>
            </a>
        </div>
        <div className="flex scrollable hover">
            <div className="nav-active-text-primary" data-nav>
                <ul className="nav">
                    <li className="nav-header hidden-folded"><span className="text-muted">Navigation</span></li>
                    <li>
                        <NavLink to={routeCodes.DASHBOARD} className=""><span className="nav-icon"><i data-feather="home"></i></span>
                            <span className="nav-text">Dashboard</span>
                        </NavLink>
                    </li>
                    {
                    userData.roleId == 1
                    ?
                    <li>
                        <NavLink to={routeCodes.USERS_LIST} className=""><span className="nav-icon"><i data-feather="users"></i></span>
                            <span className="nav-text">Admins & Vendors</span>
                        </NavLink>
                    </li>
                    :
                    null 
                    }
                    <li>
                        <NavLink to={routeCodes.CATEGORIES_LIST} className=""><span className="nav-icon"><i data-feather="calendar"></i></span>
                            <span className="nav-text">Categories</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to={routeCodes.SERVICES_LIST} className=""><span className="nav-icon"><i data-feather="calendar"></i></span>
                            <span className="nav-text">Services</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to={routeCodes.VENDORS_LIST} className=""><span className="nav-icon"><i data-feather="message-circle"></i></span>
                            <span className="nav-text">Branches</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to={routeCodes.EMPLOYEES_LIST} className=""><span className="nav-icon"><i data-feather="users"></i></span>
                            <span className="nav-text">Employees</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to={routeCodes.CUSTOMERS_LIST} className=""><span className="nav-icon"><i data-feather="users"></i></span>
                            <span className="nav-text">Customers</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to={routeCodes.BOOKINGS} className=""><span className="nav-icon"><i data-feather="mail"></i></span>
                            <span className="nav-text">Bookings</span>
                        </NavLink>
                    </li>
                    {/* <li>
                        <a href="report.html" className=""><span className="nav-icon"><i data-feather="file"></i></span>
                            <span className="nav-text">Report</span>
                        </a>
                    </li> */}
                    {
                    userData.roleId == 1
                    ?
                    <li>
                        <NavLink to={routeCodes.SETTINGS} className=""><span className="nav-icon"><i data-feather="disc"></i></span>
                            <span className="nav-text">Settings</span>
                        </NavLink>
                    </li>
                    :
                    null 
                    }
                    {
                    userData.roleId == 1
                    ?
                    <li>
                        <NavLink to={routeCodes.OFFERS_LIST} className=""><span className="nav-icon"><i data-feather="disc"></i></span>
                            <span className="nav-text">Offers</span>
                        </NavLink>
                    </li>
                    :
                    null 
                    }
                    <li>
                        <a onClick={logout} className=""><span className="nav-icon"><i data-feather="lock"></i></span>
                            <span className="nav-text">Logout</span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
</>

const mapStateToProps = state => ({
  searchTerm: state.vendorState.searchTerm,
});

const mapDispatchToProps = dispatch => ({
  onInitialLoad: userId => dispatch({ type: GET_VENDORS, userId }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
