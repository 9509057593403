import React from 'react';
import { connect } from 'react-redux';
import { GET_VENDORS } from '../actions/vendorActions';
import { getLoggedInUser, asyncLocalStorage } from '../globals/utils';
const userData = getLoggedInUser();

const logout = () => {
    asyncLocalStorage.removeItem("userData").then(function (value) {
        window.location = "/login";
    });
}

const Header = () =>
<>
    <div id="header" className="page-header header-bg-custom">
        <div className="navbar navbar-expand-lg">
            <a href="index.html" className="navbar-brand d-lg-none">
                <img src="./../img/custom/logo.png" alt="..."/>
                <span className="hidden-folded d-inline l-s-n-1x d-lg-none logo-text-color">D - Barber</span>
            </a>
            <div className="collapse navbar-collapse order-2 order-lg-1" id="navbarToggler">
                <h3 className="custom-page-title">D-BARBER</h3>
            </div>
            <ul className="nav navbar-menu order-1 order-lg-2">
                <li className="nav-item dropdown">
                    <a href="#" data-toggle="dropdown" className="nav-link d-flex align-items-center px-2 text-color">
                        <span className="mx-2 d-none l-h-1x d-lg-block text-right text-white">
                            <small className="text-fade d-block mb-1 text-white">Hello, Welcome</small>
                            {userData.firstName+" "+userData.lastName}</span>
                        <span className="avatar w-24" ><img src="./../img/a0.jpg"
                                alt="..."/></span>
                    </a>
                    <div className="dropdown-menu dropdown-menu-right w mt-3 animate fadeIn">
                        <a className="dropdown-item" href="page.profile.html"><span>Profile</span> </a>
                        <a className="dropdown-item" onClick={logout}>Sign out</a>
                    </div>
                </li>
                <li className="nav-item d-lg-none">
                    <a className="nav-link px-1" data-toggle="modal" data-target="#aside">
                        <i data-feather="menu"></i>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</>

const mapStateToProps = state => ({
  searchTerm: state.vendorState.searchTerm,
});

const mapDispatchToProps = dispatch => ({
  onInitialLoad: userId => dispatch({ type: GET_VENDORS, userId }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
