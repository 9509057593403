import { GET_EMPLOYEES, ADD_EMPLOYEE, GET_EMPLOYEE_DETAILS, GET_EMPLOYEES_BY_VENDOR } from "../actions/employeeActions";

const INITIAL_STATE = {
  employees: [],
  addEmployee: null,
  employeeDetails: null
};

function employeesReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_EMPLOYEES:
      return {...state, employees: action.data};
    case GET_EMPLOYEES_BY_VENDOR:
      return {...state, employeesByVendor: action.data};
    case ADD_EMPLOYEE:
      return {...state, addEmployee: action.data};
    case GET_EMPLOYEE_DETAILS:
      return {...state, employeeDetails: action.data};
    default: return state;
  }
}

export default employeesReducer;
