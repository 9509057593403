export const GET_BOOKINGS = 'GET_BOOKINGS';
export const GET_BOOKINGS_BY_VENDOR = 'GET_BOOKINGS_BY_VENDOR';

export function getBookings(data) {
    return {
      type: GET_BOOKINGS,
      data,
    };
}

export function getBookingsByVendor(data) {
  return {
    type: GET_BOOKINGS_BY_VENDOR,
    data,
  };
}