import { GET_SERVICES, ADD_SERVICE, GET_SERVICE_DETAILS } from "../actions/servicesAction";

const INITIAL_STATE = {
  services: [],
  addService: null
};

function servicesReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_SERVICES:
      return {...state, services: action.data};
    case ADD_SERVICE:
      return {...state, addService: action.data};
    case GET_SERVICE_DETAILS:
      return {...state, serviceDetails: action.data};
    default: return state;
  }
}

export default servicesReducer;
