import { GET_USERS, ADD_USER, GET_USER_DETAILS } from "../actions/userActions";

const INITIAL_STATE = {
  users: [],
  addUser: null,
  userDetails: null
};

function usersReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_USERS:
      return {...state, users: action.data};
    case ADD_USER:
      return {...state, addUser: action.data};
    case GET_USER_DETAILS:
      return {...state, userDetails: action.data};
    default: return state;
  }
}

export default usersReducer;
