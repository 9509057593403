import { GET_BOOKINGS, GET_BOOKINGS_BY_VENDOR } from "../actions/bookingActions";

const INITIAL_STATE = {
  bookings: [],
  bookingsByVendor: []
};

function bookingsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_BOOKINGS:
      return {...state, bookings: action.data};
    case GET_BOOKINGS_BY_VENDOR:
      return {...state, bookingsByVendor: action.data};
    default: return state;
  }
}

export default bookingsReducer;
