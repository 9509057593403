import loadable from '@loadable/component';
import React from 'react';
import {  Route, Switch, Redirect } from 'react-router-dom';
import { getLoggedInUser } from '../globals/utils';

const publicPath = '/';
export const routeCodes = {
  DASHBOARD: publicPath,
  LOGIN: `${publicPath}login`,
  REGISTER: `${publicPath}register`,
  VENDORS_LIST: `${publicPath}vendor`,
  ADD_NEW_VENDOR: `${publicPath}add-vendor`,
  EDIT_VENDOR: `${publicPath}edit-vendor`,
  VENDOR_DETAILS: `${publicPath}vendor-details`,
  SERVICES_LIST: `${publicPath}service`,
  ADD_NEW_SERVICE: `${publicPath}add-service`,
  EDIT_SERVICE: `${publicPath}edit-service`,
  CATEGORIES_LIST: `${publicPath}category`,
  ADD_NEW_CATEGORY: `${publicPath}add-category`,
  EDIT_CATEGORY: `${publicPath}edit-category`,
  EMPLOYEES_LIST: `${publicPath}employee`,
  ADD_NEW_EMPLOYEE: `${publicPath}add-employee`,
  EDIT_EMPLOYEE: `${publicPath}edit-employee`,
  USERS_LIST: `${publicPath}user`,
  ADD_NEW_USER: `${publicPath}add-user`,
  EDIT_USER: `${publicPath}edit-user`,
  CUSTOMERS_LIST: `${publicPath}customer`,
  ADD_NEW_CUSTOMER: `${publicPath}add-customer`,
  EDIT_CUSTOMER: `${publicPath}edit-customer`,
  BOOKINGS: `${publicPath}bookings`,
  SETTINGS: `${publicPath}settings`,
  OFFERS_LIST: `${publicPath}offers`,
  EDIT_OFFER: `${publicPath}edit-offer`,
  ADD_NEW_OFFER: `${publicPath}add-offer`,
  VENDOR_GALLARY: `${publicPath}vendor-gallary`,
}

const userLoggedIn = getLoggedInUser();

export default (props) => (
    userLoggedIn != null ? 
    <Switch>
        <Route exact path={routeCodes.DASHBOARD} component={loadable(() => import('../pages/home')) } />
        <Route exact path={routeCodes.LOGIN} component={loadable(() => import('../pages/users')) } />
        <Route path={routeCodes.VENDORS_LIST} component={loadable(() => import('../pages/vendors'))} />
        <Route path={routeCodes.ADD_NEW_VENDOR} component={loadable(() => import('../pages/vendors/addNew'))} />
        <Route path={routeCodes.EDIT_VENDOR+"/:id"} component={loadable(() => import('../pages/vendors/addNew'))} />
        <Route path={routeCodes.VENDOR_GALLARY+"/:id"} component={loadable(() => import('../pages/vendors/gallary'))} />
        <Route path={routeCodes.VENDOR_DETAILS+"/:id"} component={loadable(() => import('../pages/vendors/details'))} />
        <Route path={routeCodes.SERVICES_LIST} component={loadable(() => import('../pages/services'))} />
        <Route path={routeCodes.ADD_NEW_SERVICE} component={loadable(() => import('../pages/services/addNew'))} />
        <Route path={routeCodes.EDIT_SERVICE+"/:id"} component={loadable(() => import('../pages/services/addNew'))} {...props} />
        <Route path={routeCodes.CATEGORIES_LIST} component={loadable(() => import('../pages/categories'))} />
        <Route exact path={routeCodes.ADD_NEW_CATEGORY} component={loadable(() => import('../pages/categories/addNew'))} />
        <Route exact path={routeCodes.EDIT_CATEGORY+"/:id"} component={loadable(() => import('../pages/categories/addNew'))} />
        <Route path={routeCodes.EMPLOYEES_LIST} component={loadable(() => import('../pages/employees'))} />
        <Route exact path={routeCodes.ADD_NEW_EMPLOYEE} component={loadable(() => import('../pages/employees/addNew'))} />
        <Route exact path={routeCodes.EDIT_EMPLOYEE+"/:id"} component={loadable(() => import('../pages/employees/addNew'))} />
        <Route path={routeCodes.USERS_LIST} component={loadable(() => import('../pages/users/list'))} />
        <Route exact path={routeCodes.ADD_NEW_USER} component={loadable(() => import('../pages/users/addNew'))} />
        <Route exact path={routeCodes.EDIT_USER+"/:id"} component={loadable(() => import('../pages/users/addNew'))} />
        <Route path={routeCodes.CUSTOMERS_LIST} component={loadable(() => import('../pages/customers'))} />
        <Route exact path={routeCodes.ADD_NEW_CUSTOMER} component={loadable(() => import('../pages/customers/addNew'))} />
        <Route exact path={routeCodes.EDIT_CUSTOMER+"/:id"} component={loadable(() => import('../pages/customers/addNew'))} />
        <Route exact path={routeCodes.BOOKINGS} component={loadable(() => import('../pages/bookings'))} />
        <Route exact path={routeCodes.SETTINGS} component={loadable(() => import('../pages/settings'))} />
        <Route exact path={routeCodes.OFFERS_LIST} component={loadable(() => import('../pages/offers'))} />
        <Route exact path={routeCodes.ADD_NEW_OFFER} component={loadable(() => import('../pages/offers/addNew'))} />
        <Route exact path={routeCodes.EDIT_OFFER+"/:id"} component={loadable(() => import('../pages/offers/addNew'))} />
    </Switch>
    :
    <Switch>
        <Route exact path="/" render={() => (
        <Redirect to="/login"/>
        )}/>
        <Route path={routeCodes.LOGIN} component={loadable(() => import('../pages/users')) } />
        <Route exact path={routeCodes.REGISTER} component={loadable(() => import('../pages/users/register')) } />
    </Switch>
);
