export const GET_VENDORS = 'GET_VENDORS';
export const ADD_VENDOR = 'ADD_VENDOR';
export const GET_VENDOR_DETAILS = 'GET_VENDOR_DETAILS';
export const SAVE_VENDOR_GALLARY = 'SAVE_VENDOR_GALLARY';
export const DELETE_VENDOR_GALLARY = 'DELETE_VENDOR_GALLARY';

export function getVendors(data) {
    return {
      type: GET_VENDORS,
      data,
    };
}

export function addVendor(data) {
    return {
      type: ADD_VENDOR,
      data,
    };
}

export function getVendorDetails(data) {
    return {
      type: GET_VENDOR_DETAILS,
      data,
    };
}

export function saveVendorGallary(data) {
  return {
    type: SAVE_VENDOR_GALLARY,
    data,
  };
}

export function deleteVendorGallary(data) {
  return {
    type: DELETE_VENDOR_GALLARY,
    data,
  };
}