export const GET_OFFERS = 'GET_OFFERS';
export const ADD_OFFER = 'ADD_OFFER';
export const GET_OFFER_DETAILS = 'GET_OFFER_DETAILS';
export const DELETE_OFFER = 'DELETE_OFFER';

export function getOffers(data) {
    return {
      type: GET_OFFERS,
      data,
    };
}

export function addOffer(data) {
    return {
      type: ADD_OFFER,
      data,
    };
}

export function getOfferDetails(data) {
    return {
      type: GET_OFFER_DETAILS,
      data,
    };
}

export function deleteOffer(data) {
  return {
    type: DELETE_OFFER,
    data,
  };
}