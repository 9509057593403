import axios from 'axios';
import { getLoggedInUser } from './utils';

axios.interceptors.request.use(function(config) {
  const userData = getLoggedInUser();

  if ( userData != null ) {
    config.headers.loggedInUser = userData.id;
    config.headers.loggedInUserRole = userData.roleId;
  }

  return config;
}, function(err) {
  return Promise.reject(err);
});