import { GET_OFFERS, ADD_OFFER, GET_OFFER_DETAILS, DELETE_OFFER } from "../actions/offerActions";

const INITIAL_STATE = {
  offers: [],
  addOffer: null,
  offerDetails: null
};

function offersReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_OFFERS:
      return {...state, offers: action.data};
    case ADD_OFFER:
      return {...state, addOffer: action.data};
    case GET_OFFER_DETAILS:
      return {...state, offerDetails: action.data};
    case DELETE_OFFER:
      return {...state, offerDelete: action.data};
    default: return state;
  }
}

export default offersReducer;
