import { GET_CUSTOMERS, ADD_CUSTOMER, GET_CUSTOMER_DETAILS } from "../actions/customerActions";

const INITIAL_STATE = {
  customers: [],
  addCustomer: null,
  customerDetails: null
};

function customersReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_CUSTOMERS:
      return {...state, customers: action.data};
    case ADD_CUSTOMER:
      return {...state, addCustomer: action.data};
    case GET_CUSTOMER_DETAILS:
      return {...state, customerDetails: action.data};
    default: return state;
  }
}

export default customersReducer;
