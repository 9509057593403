export const GET_SERVICES = 'GET_SERVICES';
export const ADD_SERVICE = 'ADD_SERVICE';
export const GET_SERVICE_DETAILS = 'GET_SERVICE_DETAILS';

export function getServices(data) {
    return {
      type: GET_SERVICES,
      data,
    };
}

export function addService(data) {
    return {
      type: ADD_SERVICE,
      data,
    };
}

export function getServiceDetails(data) {
  return {
    type: GET_SERVICE_DETAILS,
    data,
  };
}