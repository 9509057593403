import React from 'react';
import { connect } from 'react-redux';
import { GET_VENDORS } from '../actions/vendorActions';

const Footer = () =>
    <div id="footer" className="page-footer hide">
        <div className="d-flex p-3"><span className="text-sm text-muted flex">&copy; Copyright. dbarbar.com</span>
            <div className="text-sm text-muted">Version 1.0.0</div>
        </div>
    </div>

const mapStateToProps = state => ({
  searchTerm: state.vendorState.searchTerm,
});

const mapDispatchToProps = dispatch => ({
  onInitialLoad: userId => dispatch({ type: GET_VENDORS, userId }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
