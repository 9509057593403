import { GET_PROFILE_DETAILS, SETTINGS, SAVE_SETTINGS, SAVE_GALLARY, GET_GALLARY, UPDATE_GALLARY, DELETE_GALLARY } from "../actions/homeActions";

const INITIAL_STATE = {
  userData: '',
  saveGallary: null,
  getGallary: null,
  updateGallary: null,
  deleteGallary: null
};

function homeReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_PROFILE_DETAILS:
      return { ...state, userData: action.data };
    case SETTINGS:
      return { ...state, settings: action.data };
    case SAVE_SETTINGS:
      return { ...state, saveSettings: action.data };
    case GET_GALLARY:
      return { ...state, getGallary: action.data };
    case SAVE_GALLARY:
      return { ...state, saveGallary: action.data };
    case UPDATE_GALLARY:
      return { ...state, updateGallary: action.data };
    case DELETE_GALLARY:
      return { ...state, deleteGallary: action.data };
    default: return state;
  }
}

export default homeReducer;
