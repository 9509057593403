import { GET_VENDORS, ADD_VENDOR, GET_VENDOR_DETAILS, SAVE_VENDOR_GALLARY, DELETE_VENDOR_GALLARY } from "../actions/vendorActions";

const INITIAL_STATE = {
  vendors: [],
  addVendor: null,
  vendorDetails: null,
  saveVendorGallary: null,
  deleteVendorGallary: null
};

function vendorsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_VENDORS:
      return {...state, vendors: action.data};
    case ADD_VENDOR:
      return {...state, addVendor: action.data};
    case GET_VENDOR_DETAILS:
      return {...state, vendorDetails: action.data};
    case SAVE_VENDOR_GALLARY:
      return {...state, saveVendorGallary: action.data};
    case DELETE_VENDOR_GALLARY:
      return {...state, deleteVendorGallary: action.data};
    default: return state;
  }
}

export default vendorsReducer;
