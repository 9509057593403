import React from 'react';
import { connect } from 'react-redux';
import { GET_VENDORS } from '../actions/vendorActions';
import Header from './Header';
import Routes from './Routes';
import Footer from './Footer';
import Sidebar from './Sidebar';
import { BrowserRouter as Router } from 'react-router-dom';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { getLoggedInUser } from '../globals/utils';
import {axios} from "./../globals/axiosCommon";

const userLoggedIn = getLoggedInUser();

const App = () =>
  <>
  <Router>
    { userLoggedIn != null ? <Sidebar/> : null }
    <div id="main" className="layout-column flex">
    { userLoggedIn != null ? <Header/> : null }
      <Routes />
    </div>
    { userLoggedIn != null ? <Footer /> : null }
    </Router>
  </>

const mapStateToProps = state => ({
  searchTerm: state.vendorState.searchTerm,
});

const mapDispatchToProps = dispatch => ({
  onInitialLoad: userId => dispatch({ type: GET_VENDORS, userId }),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
