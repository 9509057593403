export const GET_EMPLOYEES = 'GET_EMPLOYEES';
export const GET_EMPLOYEES_BY_VENDOR = 'GET_EMPLOYEES_BY_VENDOR';
export const ADD_EMPLOYEE = 'ADD_EMPLOYEE';
export const GET_EMPLOYEE_DETAILS = 'GET_EMPLOYEE_DETAILS';

export function getEmployees(data) {
    return {
      type: GET_EMPLOYEES,
      data,
    };
}

export function getEmployeesByVendor(data) {
  return {
    type: GET_EMPLOYEES_BY_VENDOR,
    data,
  };
}

export function addEmployee(data) {
    return {
      type: ADD_EMPLOYEE,
      data,
    };
}

export function getEmployeeDetails(data) {
    return {
      type: GET_EMPLOYEE_DETAILS,
      data,
    };
}