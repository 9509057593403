export function onlyNumberKey(evt) {

    // Only ASCII charactar in that range allowed 
    var ASCIICode = (evt.which) ? evt.which : evt.keyCode
    if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57))
        return false;
    return true;
}

export function getLoggedInUser()
{
    let userData = localStorage.getItem('userData');
    if(userData !== undefined && userData !== null)
    {
        return JSON.parse(userData);
    }
    else 
    {
        return null;
    }
}

export const asyncLocalStorage = {
    setItem: function (key, value) {
        return Promise.resolve().then(function () {
            localStorage.setItem(key, value);
        });
    },
    getItem: function (key) {
        return Promise.resolve().then(function () {
            return localStorage.getItem(key);
        });
    },
    removeItem:function (key) {
        return Promise.resolve().then(function () {
            return localStorage.removeItem(key);
        });
    } 
};

export function formatAMPM(date) {
    var hours = date.split(":")[0];
    var minutes = date.split(":")[1];
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes > 0 && minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
}

export function searchArray(list, keyword)
{
    var list = list.filter((item) => {
        let found = Object.values(item).filter((value) => {
          return value !== undefined && value !== null && new String(value).toLowerCase().includes(keyword.toLowerCase());
        })
        return found.length > 0;
    })
    return list;
}