export const GET_USERS = 'GET_USERS';
export const ADD_USER = 'ADD_USER';
export const GET_USER_DETAILS = 'GET_USER_DETAILS';

export function getUsers(data) {
    return {
      type: GET_USERS,
      data,
    };
}

export function addUser(data) {
    return {
      type: ADD_USER,
      data,
    };
}

export function getUserDetails(data) {
    return {
      type: GET_USER_DETAILS,
      data,
    };
}