import { GET_CATEGORIES, ADD_CATEGORY, GET_CATEGORY_DETAILS } from "../actions/categoriesAction";

const INITIAL_STATE = {
  categories: [],
  addCategory: null
};

function categoriesReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_CATEGORIES:
      return {...state, categories: action.data};
    case ADD_CATEGORY:
      return {...state, addCategory: action.data};
    case GET_CATEGORY_DETAILS:
      return {...state, categoryDetails: action.data};
    default: return state;
  }
}

export default categoriesReducer;
