export const GET_CUSTOMERS = 'GET_CUSTOMERS';
export const ADD_CUSTOMER = 'ADD_CUSTOMER';
export const GET_CUSTOMER_DETAILS = 'GET_CUSTOMER_DETAILS';

export function getCustomers(data) {
    return {
      type: GET_CUSTOMERS,
      data,
    };
}

export function addCustomer(data) {
    return {
      type: ADD_CUSTOMER,
      data,
    };
}

export function getCustomerDetails(data) {
    return {
      type: GET_CUSTOMER_DETAILS,
      data,
    };
}