export const GET_CATEGORIES = 'GET_CATEGORIES';
export const ADD_CATEGORY = 'ADD_CATEGORY';
export const GET_CATEGORY_DETAILS = 'GET_CATEGORY_DETAILS';

export function getCategories(data) {
    return {
      type: GET_CATEGORIES,
      data,
    };
}

export function addCategory(data) {
    return {
      type: ADD_CATEGORY,
      data,
    };
}


export function getCategoryDetails(data) {
  return {
    type: GET_CATEGORY_DETAILS,
    data,
  };
}