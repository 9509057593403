import { combineReducers } from 'redux';

import adminReducer from './adminReducer';
import vendorsReducer from './vendorsReducer';
import servicesReducer from './servicesReducer';
import categoriesReducer from './categoriesReducer';
import employeesReducer from "./employeesReducer";
import usersReducer from "./usersReducer";
import customersReducer from "./customersReducer";
import bookingsReducer from "./bookingsReducer";
import offersReducer from "./offersReducer";

const rootReducer = combineReducers({
  adminState: adminReducer,
  vendorState: vendorsReducer,
  serviceState: servicesReducer,
  categoryState: categoriesReducer,
  employeeState: employeesReducer,
  userState: usersReducer,
  customerState: customersReducer,
  bookingState: bookingsReducer,
  offerState: offersReducer
});

export default rootReducer;